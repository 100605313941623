import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_URL } from "../constants";
import agent from './agent'
import { QueryInput } from "../@types/incident";

export const fetchIncidents = createAsyncThunk(
  "incident/fetchIncident",
  async (query: QueryInput) => {
    const response = await agent.get(`${BACKEND_URL}/incidents?page=${query.page}&pageSize=${query.pageSize}`);
    return response.data
  },
);

export const fetchIncidentDetail = createAsyncThunk(
  "incident/fetchIncidentDetail",
  async (id: string) => {
    const response = await agent.get(`${BACKEND_URL}/incidents/${id}`);
    return response.data
  },
);
