import { Input, Space } from "antd";
import React, { useState } from "react";
import removeIcon from "../../../../assets/images/remove.png";

interface Props {
  value: string[];
  onChange: (email: string , isRemove: boolean) => void;
}

const Email: React.FC<Props> = ({ value, onChange }) => {
  const handleAdd = (email: string) => {
    onChange(email, false);
    setInputValue("");
  };

  const [inputValue, setInputValue] = useState<string>("");

  const handleRemove = (email: string) => {
    onChange(email , true);
  };

  return (
    <div className="border-container border p-6 rounded-2xl relative h-full">
      <h3 className="text-2xl text-container mb-4">
        Email addresses of receivers{" "}
      </h3>
      <Space.Compact className="w-full">
        <Input
          className="ant-input-large"
          type="email"
          placeholder="Input Email"
          autoComplete="false"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleAdd(inputValue)}
        />
      </Space.Compact>

      <div
        style={{ maxHeight: "200px", overflowY: "auto" }}
        className="grid grid-cols-1 gap-4 mt-4 px-4 scroller">
        {value.map((email) => (
          <div key={email} className="flex items-center justify-between">
            <h5 className="text-left text-secondary text-base">{email}</h5>
            <img
              className="cursor-pointer hover:scale-110 transition duration-100"
              src={removeIcon}
              alt="Icon"
              onClick={() => handleRemove(email)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Email;
