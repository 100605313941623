import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  PreloadedState,
} from "@reduxjs/toolkit";
import settingReducer from "./slices/setting.slice";
import incidentReducer from "./slices/incident.slice";
import communicationReducer from "./slices/communication.slide";
import notificationTypeReducer from "./slices/notification-type.slide";
import notificationContentReducer from "./slices/notification-content.slide";
import reportReducer from "./slices/report.slice";

const rootReducer = combineReducers({
  setting: settingReducer,
  incident: incidentReducer,
  communication: communicationReducer,
  notificationType : notificationTypeReducer,
  notificationContent : notificationContentReducer,
  report : reportReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppStore = ReturnType<typeof setupStore>;
