import { Table } from "antd";
import { useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ICommunication } from "../../@types/communication";
import { setPage } from "../../app/slices/communication.slide";
import { fetchCommunications } from "../../api/communication";
import { get } from "lodash";
import ReactAudioPlayer from "react-audio-player";

const convertDateTime = (dbDateString: string) => {
  const dbDate = new Date(dbDateString);

  // Options for formatting the date and time

  // Format the date and time using Intl
  const dateTimeFormatted = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris", // CEST time zone
  }).format(dbDate);
  return dateTimeFormatted;
};
const columns: ColumnsType<ICommunication> = [
  {
    title: "Type",
    key: "type",
    render: (_, record) => <div>{record.type}</div>,
  },
  {
    title: "Receiver",
    key: "receiver",
    render: (_, record) => <div>{record.receiver}</div>,
  },
  {
    title: "Status",
    key: "status",
    render: (_, record) => <div>{record.status}</div>,
  },
  {
    title: "Subject",
    key: "subject",
    render: (_, record) => <div>{record.mail_subject}</div>,
  },
  {
    title: "Content",
    key: "content",
    render: (_, record) =>
      record.type === "phone" ? (
        <ReactAudioPlayer
          src={`${process.env.REACT_APP_BACKEND_URL}/phone-script/${record.incident_id}`}
          controls
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: record.mail_content || record.sms_content,
          }}
        />
      ),
  },
  {
    title: "IncidentId",
    key: "incidentId",
    render: (_, record) => <div>{get(record, "incident_id")}</div>,
  },
  {
    title: "Created At",
    key: "createdAt",
    render: (_, record) => <div>{convertDateTime(record.created_at)}</div>,
  },
];

export const CommunicationsTable = () => {
  const { list, total, currentPage, pageSize, loading } = useAppSelector(
    (s) => s.communication,
  );
  const dispatch = useAppDispatch();

  const handleChangePage = (p: number) => {
    dispatch(setPage(p));
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    dispatch(
      fetchCommunications({
        page: currentPage,
        pageSize,
      }),
    );
  }, [currentPage, dispatch, pageSize]);

  return (
    <Table
      scroll={{ x: 1400 }}
      columns={columns}
      dataSource={list}
      loading={loading}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total,
        onChange: handleChangePage,
        showSizeChanger: false,
      }}
    />
  );
};
