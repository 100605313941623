import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from './agent'
import { BACKEND_URL } from "../constants";


export const fetchNotificationType = createAsyncThunk(
  "notificationType/fetchNotificationType",
  async (platform : string) => {
    const response = await agent.get(`${BACKEND_URL}/notification-types/${platform}`);
    return response.data
  },
);
