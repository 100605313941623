import { Button, Input, Popover, Select, Spin, Table, message } from "antd";
import { useState, useCallback, useEffect, FC, useMemo } from "react";
import { AiFillInfoCircle } from "react-icons/ai";
import RichTextEditor, { EditorValue } from "react-rte";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { debounce } from "lodash";
import { updateAdvanceContent, updateEmailContent } from "../../../api/setting";
import type { ColumnsType } from "antd/es/table";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  triggerSave: boolean;
  setSaving: Function;
}

const Message: FC<IProps> = ({ triggerSave, setSaving }) => {
  const {
    mailSubject,
    mailContent,
    contentLoading,
    loadingAdvanceContent,
    advanceContent,
  } = useAppSelector((state) => state.notificationContent);

  const [subject, setSubject] = useState<string>("");
  const [content, setContent] = useState<EditorValue>(
    RichTextEditor.createEmptyValue(),
  );
  const [messageAdvance, setMessageAdvance] = useState<any[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSubject(mailSubject);
    setContent(RichTextEditor.createValueFromString(mailContent, "html"));
  }, [mailContent, mailSubject]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSave = useCallback(
    debounce(async (subject, content) => {
      await dispatch(
        updateEmailContent({
          subject,
          content,
        }),
      );
      message.success("Updated content successfully");
    }, 200),
    [],
  );

  useEffect(() => {
    if (advanceContent) {
      setMessageAdvance(JSON.parse(advanceContent));
    } else {
      setMessageAdvance([]);
    }
  }, [advanceContent]);

  const columns: ColumnsType<Record<string, any>> = [
    {
      title: "Variable",
      key: "name",
      render: (_: any, record: any) => (
        <div>
          {"{"}
          {record.name}
          {"}"}
        </div>
      ),
    },
    {
      title: "Description",
      key: "description",
      render: (_: any, record: any) => record.description,
    },
  ];

  const listVariable = [
    {
      name: "severity",
      description: (
        <div>
          It will be <strong>Warning</strong>, <strong>Critical</strong> or{" "}
          <strong>Alert</strong>
        </div>
      ),
    },
    {
      name: "device.name",
      description: <div>Name of device</div>,
    },
    {
      name: "location.room",
      description: <div>Device room location</div>,
    },
    {
      name: "threshold.value",
      description: <div>Threshold value</div>,
    },
    {
      name: "threshold.duration",
      description: <div>Threshold duration</div>,
    },
    {
      name: "channel.name",
      description: <div>Channel name</div>,
    },
    {
      name: "channel.unit",
      description: <div>Channel unit</div>,
    },
    {
      name: "channel.port",
      description: <div>Channel port</div>,
    },
  ];

  const variableContent = (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            message.success("Copy variable to clipboard");
            navigator.clipboard.writeText(`{${record.name}}`);
          },
        };
      }}
      columns={columns}
      dataSource={listVariable}
      pagination={false}
    />
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnAdvanceEdit: ColumnsType<Record<string, any>> = [
    {
      title: "Message",
      key: "message",
      render: (_: any, record: any) => (
        <Input
          onChange={(e) =>
            handleChangeRowValue(record.id, "message", e.target.value)
          }
          value={record.message}
        />
      ),
    },
    {
      title: "Variable",
      key: "variable",
      render: (_: any, record: any) => (
        <Input
          onChange={(e) =>
            handleChangeRowValue(record.id, "variable", e.target.value)
          }
          value={record.variable}
        />
      ),
    },
    {
      title: "Type",
      key: "type",
      render: (_: any, record: any) => (
        <Select
          defaultValue="mandatory"
          options={[
            { value: "mandatory", label: "Mandatory" },
            { value: "optional", label: "Optional" },
          ]}
          value={record.type}
          onChange={(e) => handleChangeRowValue(record.id, "type", e)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Button onClick={() => handleRemoveRow(record.id)}> Remove</Button>
      ),
    },
  ];

  const handleChangeRowValue = (id: string, type: string, value: string) => {
    setMessageAdvance((a) =>
      a.map((row) => {
        if (row.id === id) {
          row[type] = value;
        }
        return row;
      }),
    );
  };

  const handleRemoveRow = (id: string) => {
    setMessageAdvance((a) => a.filter((i) => i.id !== id));
  };

  const handleAddRow = () => {
    setMessageAdvance((a) => [
      ...a,
      {
        id: uuidv4(),
        message: "",
        variable: "",
        type: "mandatory",
      },
    ]);
  };

  const renderAdvanceEdit = useMemo(() => {
    const handleSaveAdvance = (data: any[]) => {
      if (!data.length) return;
      dispatch(updateAdvanceContent({ content: JSON.stringify(data) }));
    };
    return (
      <div>
        <Table
          columns={columnAdvanceEdit}
          dataSource={messageAdvance}
          pagination={false}
        />
        <div className="mt-4 flex justify-end">
          <Button
            className="text-base rounded-3xl flex items-center justify-center mr-4"
            onClick={handleAddRow}>
            Add Row
          </Button>
          <Button
            onClick={() => handleSaveAdvance(messageAdvance)}
            loading={loadingAdvanceContent}
            className="text-white text-base rounded-3xl bg-primary flex items-center justify-center">
            Save
          </Button>
        </div>
      </div>
    );
  }, [columnAdvanceEdit, messageAdvance, loadingAdvanceContent, dispatch]);

  useEffect(() => {
    if (triggerSave) {
      handleSave(subject, content.toString("html"));
      setSaving(false);
    }
  }, [content, handleSave, setSaving, subject, triggerSave]);

  return (
    <div className="w-full h-full">
      {contentLoading ? (
        <div className="flex w-full h-full items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-4">
          <div>
            <div className="text-sm text-container mb-2 ml-2">Email / SMS</div>
            <Input
              className="ant-input-large"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder={`Subject`}
            />
          </div>
          <div>
            <div className="text-sm text-container mb-2 ml-2 flex items-center justify-between">
              <div className="flex items-center">
                <div>Content</div>
                <div
                  className="ml-2 cursor-pointer"
                  title="Variable Information">
                  <Popover
                    placement="bottomLeft"
                    content={variableContent}
                    trigger="click">
                    <AiFillInfoCircle width={18} height={18} size={18} />
                  </Popover>
                </div>
              </div>
              <Popover
                placement="right"
                content={renderAdvanceEdit}
                trigger="click">
                <div className="bg-primary text-white py-1 px-4 cursor-pointer rounded-sm">
                  Advanced Edit
                </div>
              </Popover>
            </div>
            <RichTextEditor
              value={content}
              onChange={(value: EditorValue) => setContent(value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
