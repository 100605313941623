import { createSlice } from '@reduxjs/toolkit'
import { ISetting } from '../../@types/setting'
import { fetchSetting, updateLabOperatorInstance } from '../../api/setting'

const initialState: ISetting = {
    lapOperator: {
        clientId: '',
        clientSecret: '',
        tenant: ''
    },
    emails: [],
    phones: [],
    emailContent: '',
    emailSubject: '',
    voiceContent: '',
    loadingLap: false,
    loadingMessage: false,
    platform: ''
}

const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(fetchSetting.fulfilled, (state, action) => {
            state.emails = action.payload.emails
            state.phones = action.payload.phones
            state.lapOperator = action.payload.lapOperator
            state.emailContent = action.payload.emailContent
            state.emailSubject = action.payload.emailSubject
            state.voiceContent = action.payload.voiceContent
            state.platform = action.payload.platform
        })

        builder.addCase(updateLabOperatorInstance.fulfilled, (state, action) => {
            state.lapOperator = action.payload
            state.loadingLap = false
        })

        builder.addCase(updateLabOperatorInstance.pending, (state, action) => {
            state.loadingLap = true
        })
    }

})

export const { } = settingSlice.actions

export default settingSlice.reducer