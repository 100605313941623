import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDevices } from "../../@types/report";
import { addEmail, fetchAllDevices, fetchListEmailReportSetting, fetchReportSchedule, fetchReports, removeEmail } from "../../api/reports";
import { split, compact } from "lodash";


const initialState: {
    list: any[],
    total: number,
    currentPage: number,
    pageSize: number,
    loadingList: boolean,
    devices: IDevices[],
    loadingDevice: boolean,
    listManualEmail: string[],
    reportSchedule: Record<string, any>
} = {
    list: [],
    loadingList: false,
    total: 0,
    currentPage: 1,
    pageSize: 10,
    devices: [],
    loadingDevice: false,
    listManualEmail: [],
    reportSchedule: {
        devices: [],
        interval: [],
        resolution: 1,
        receivers: [],
        active: true,
        offsetRemoveDay: 15
    }
};

const reportsSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        updateReportSchedule: (state, action) => {
            switch (action.payload.type) {
                case 'devices':
                    state.reportSchedule.devices = action.payload.value
                    break
                case 'interval':
                    state.reportSchedule.interval = action.payload.value
                    break
                case 'resolution':
                    state.reportSchedule.resolution = action.payload.value
                    break
                case 'receivers':
                    state.reportSchedule.receivers = action.payload.value
                    break
                case 'active':
                    state.reportSchedule.active = action.payload.value
                    break
                case 'offsetRemoveDay':
                    state.reportSchedule.offsetRemoveDay = action.payload.value
                    break
            }
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllDevices.fulfilled, (state, action) => {
            state.devices = action.payload;
            state.loadingDevice = false
        });
        builder.addCase(fetchAllDevices.pending, (state, action) => {
            state.loadingDevice = true
        });
        builder.addCase(fetchReports.fulfilled, (state, action) => {
            state.list = action.payload.list;
            state.total = action.payload.totalCount
            state.loadingList = false
        });
        builder.addCase(fetchReports.pending, (state, action) => {
            state.loadingList = true
        });
        builder.addCase(fetchListEmailReportSetting.fulfilled, (state, action) => {
            state.listManualEmail = action.payload
        })
        builder.addCase(addEmail.fulfilled, (state, action) => {
            state.listManualEmail = [...state.listManualEmail, action.payload]
        })
        builder.addCase(removeEmail.fulfilled, (state, action) => {
            state.listManualEmail = state.listManualEmail.filter(email => email !== action.payload)
        })
        builder.addCase(fetchReportSchedule.fulfilled, (state, action) => {
            state.reportSchedule = {
                devices: compact(split(action.payload.devices, ',')),
                interval: compact(split(action.payload.interval, ',')),
                resolution: action.payload.resolution || 1,
                receivers: compact(split(action.payload.receivers, ',')),
                active: action.payload.active ?? true,
                offsetRemoveDay: action.payload.offset_remove_day || 15
            }
        })
    },
});

export const { updateReportSchedule, setPage } = reportsSlice.actions;

export default reportsSlice.reducer;
