import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { IIncident } from "../../@types/incident";
import { fetchIncidentDetail, fetchIncidents } from "../../api/incidents";

const initialState: {
  list: IIncident[],
  total: number,
  pageSize: number,
  currentPage: number,
  loading: boolean,
  incidentDetail: Record<string, any>
} =
{
  list: [],
  total: 0,
  pageSize: 10,
  currentPage: 1,
  loading: false,
  incidentDetail: {}
};

const incidentSlice = createSlice({
  name: "incident",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    },
    clearIncidentDetail: (state) => {
      state.incidentDetail = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncidents.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.total = action.payload.totalCount
      state.loading = false
    })
    builder.addCase(fetchIncidents.pending, (state, action) => {
      state.loading = true
    })

    builder.addCase(fetchIncidentDetail.fulfilled, (state, action) => {
      state.incidentDetail = action.payload
    })
  },
});

export const { setPage, clearIncidentDetail } = incidentSlice.actions;

export default incidentSlice.reducer;
