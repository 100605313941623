import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from './agent'
import { BACKEND_URL } from "../constants";
import { QueryInput } from "../@types/incident";


export const fetchAllDevices = createAsyncThunk(
  "reports/fetchAllDevices",
  async () => {
    const response = await agent.get(`${BACKEND_URL}/essentim-hubs/devices`);
    return response.data
  },
);

export const generateManualReport = createAsyncThunk(
  "reports/generateManualReport",
  async (body: any) => {
    const response = await agent.post(`${BACKEND_URL}/reports`, body);
    return response.data
  },
);

export const fetchReportSchedule = createAsyncThunk(
  "reports/fetchReportSchedule",
  async () => {
    const response = await agent.get(`${BACKEND_URL}/reports/report-schedules`);
    return response.data
  },
);

export const updateReportScheduleAPI = createAsyncThunk(
  "reports/updateReportScheduleAPI",
  async (data: any) => {
    const response = await agent.patch(`${BACKEND_URL}/reports/report-schedules`, data);
    return response.data
  },
);

export const fetchListEmailReportSetting = createAsyncThunk(
  "reports/getListEmailReportSetting",
  async () => {
    const response = await agent.get(`${BACKEND_URL}/reports/report-settings/email`);
    return response.data
  },
);

export const addEmail = createAsyncThunk(
  "reports/addEmail",
  async (email: any) => {
    await agent.post(`${BACKEND_URL}/reports/report-settings/email`, { email });
    return email
  },
);

export const removeEmail = createAsyncThunk(
  "reports/removeEmail",
  async (email: any) => {
    await agent.patch(`${BACKEND_URL}/reports/report-settings/email`, { email });
    return email
  },
);

export const fetchReports = createAsyncThunk(
  "reports/listReport",
  async (query: QueryInput) => {
    const response = await agent.get(`${BACKEND_URL}/reports?page=${query.page}&pageSize=${query.pageSize}`);
    return response.data
  },
); 