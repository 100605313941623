import { Form, Input, Button, message, Row, Col } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { updateLabOperatorInstance } from "../../api/setting";
import { PLATFORM } from "../../constants";
const LabOperatorInstance = () => {
  const {
    lapOperator,
    loadingLap: loading,
    platform,
  } = useAppSelector((state) => state.setting);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then(async (value) => {
        await dispatch(
          updateLabOperatorInstance({
            clientId: value.clientId,
            clientSecret: value.clientSecret,
            tenant: value.clientTenant,
          }),
        );
        message.success("Update lap operator instance successfully");
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    form.setFieldValue("clientId", lapOperator.clientId);
    form.setFieldValue("clientSecret", lapOperator.clientSecret);
    form.setFieldValue("clientTenant", lapOperator.tenant);
  }, [lapOperator, form]);

  if (PLATFORM.LAB_OPERATOR === platform) {
    return (
      <section className="rounded-lg bg-primaryBg pt-8 pr-8 pl-8 pb-4">
        <div className="flex justify-between">
          <h3 className="text-2xl text-container mb-2">
            Lap Operator Instance
          </h3>
          <Button
            className="text-white text-base rounded-3xl bg-primary py-5 px-6 flex items-center justify-center"
            onClick={onFinish}
            loading={loading}>
            Save
          </Button>
        </div>

        <Form
          className="mt-4"
          name="lab-operator-instance"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={form}>
          <Row gutter={[16, 16]}>
            <Col xs={24} xl={12}>
              <Form.Item
                className="abc"
                labelAlign="left"
                label="Client ID"
                name="clientId"
                rules={[{ required: true, message: "Please input client id" }]}>
                <Input className="ant-input-large" />
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
              <Form.Item
                labelAlign="left"
                label="Client Secret"
                name="clientSecret"
                rules={[
                  { required: true, message: "Please input client secret" },
                ]}>
                <Input className="ant-input-large" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelAlign="left"
                label="Client Tenant"
                name="clientTenant"
                rules={[
                  { required: true, message: "Please input client tenant" },
                ]}>
                <Input className="ant-input-large" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </section>
    );
  }

  return null;
};

export default LabOperatorInstance;
