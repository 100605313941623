import React, { useMemo, useState, useEffect } from "react";
import { DashboardOutlined, SettingOutlined } from "@ant-design/icons";
import { TiDocumentText } from "react-icons/ti";
import { Layout, Menu, MenuProps, theme } from "antd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Setting from "./pages/Setting";
import History from "./pages/History";
import EmailSms from "./pages/EmailSms";
import logo from "./assets/images/logo.png";
import { getSubscription } from "./api/setting";
import { first, get } from "lodash";
import Report from "./components/setting/Report";
import ReportHistory from "./pages/ReportHistory";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group",
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "/", <DashboardOutlined />),
  getItem("Setting", "sub", <SettingOutlined />, [
    getItem("Notification", "/setting"),
    getItem("Report", "/reports"),
  ]),
  getItem("Incidents", "/incidents", <TiDocumentText />),
  getItem("Email & SMS", "/email-sms", <TiDocumentText />),
  getItem("Report", "/report-history", <TiDocumentText />),
];

const MENU_ITEMS = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: "Dashboard",
    link: "/",
  },
  {
    key: "2",
    icon: <SettingOutlined />,
    label: "Setting",
    link: "/setting",
  },
  {
    key: "3",
    icon: <TiDocumentText />,
    label: "Incidents",
    link: "/incidents",
  },
  {
    key: "4",
    icon: <TiDocumentText />,
    label: "Email & SMS",
    link: "/email-sms",
  },
];

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [active, setActive] = useState<boolean>(false);
  const [subscriptionType, setSubscriptionType] = useState<string>();
  const [subscriptionId, setSubscriptionId] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(true);
  const renderMenu = useMemo(() => {
    let activeTab = "1";
    if (pathname.includes("setting")) activeTab = "2";
    if (pathname.includes("incidents")) activeTab = "3";
    if (pathname.includes("email-sms")) activeTab = "4";
    if (subscriptionType === "essentim") {
      return (
        <Menu
          selectedKeys={[activeTab]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          items={items}
          onClick={(e) => {
            if (e.key !== "sub") {
              navigate(`/${subscriptionId}${e.key}`);
            }
          }}
        />
      );
    }
    return (
      <Menu mode="inline" selectedKeys={[activeTab]}>
        {MENU_ITEMS.map((item) => (
          <Menu.Item
            key={item.key}
            onClick={() => {
              navigate(`/${subscriptionId}${item.link}`);
            }}>
            <div className="flex items-center">
              {item.icon}
              <div
                style={{ transform: "translateY(-1px)" }}
                className="ml-2 text-base text-container">
                {item.label}
              </div>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [navigate, pathname, subscriptionId, subscriptionType]);

  async function checkSubscription() {
    const slug = first(pathname.split("/").slice(1, 2));
    if (!slug) {
      setActive(false);
      navigate("/not-allow");
      return;
    }
    const response = await getSubscription(slug || "");
    const subscription_id = get(response.data, "id", "");
    const currentStatus = get(response.data, "status", "inactive");
    if (currentStatus !== "active") {
      setActive(false);
      navigate("/not-allow");
    } else {
      setActive(true);
      setSubscriptionType(response.data.platform);
      setSubscriptionId(subscription_id);
      localStorage.setItem("subscriptionId", subscription_id);
    }
  }
  useEffect(() => {
    checkSubscription();
  }, [pathname]);

  return (
    <Layout className="min-h-screen">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          setIsCollapsed(collapsed);
        }}>
        <div className="logo">
          <img src={logo} alt="Essentim" />
        </div>
        {active && renderMenu}
      </Sider>
      <Layout style={{ background: "#f5f5f5 !important" }}>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          {isCollapsed && (
            <div className="logo lg:hidden">
              <img src={logo} alt="Essentim" />
            </div>
          )}
        </Header>
        {active ? (
          <Content
            style={{
              background: "#f5f5f5",
            }}>
            <div className="bg-white ml-8 mr-6 my-6 mb-6 p-6 rounded-3xl">
              <Routes>
                <Route path="/:subscriptions" element={<Dashboard />} />
                <Route path="/:subscriptions/setting" element={<Setting />} />
                <Route path="/:subscriptions/incidents" element={<History />} />
                <Route path="/:subscriptions/reports" element={<Report />} />
                <Route
                  path="/:subscriptions/email-sms"
                  element={<EmailSms />}
                />
                <Route
                  path="/:subscriptions/report-history"
                  element={<ReportHistory />}
                />
              </Routes>
            </div>
          </Content>
        ) : (
          <Routes>
            <Route
              path="/not-allow"
              element={
                <div className="h-full text-center text-2xl py-6 text-container font-bold">
                  Subscription is not existing. Please provide valid one
                </div>
              }
            />
          </Routes>
        )}
      </Layout>
    </Layout>
  );
};

export default App;
