import { FC } from "react";

interface Props {
  children: React.ReactNode;
  title?: string;
  fit?: boolean;
}

const Box: FC<Props> = ({ children, title, fit = false }) => {
  return (
    <div
      className="border-container border p-6 rounded-2xl relative h-full">
      {title && (
        <div className="text-container text-xs absolute top-0 -translate-y-full left-0 px-2">
          {title}
        </div>
      )}
      {children}
    </div>
  );
};

export default Box;
