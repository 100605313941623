import { Col, Divider, Modal, Row, Table } from "antd";
import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchIncidentDetail } from "../../api/incidents";
import { get } from "lodash";
import { ColumnsType } from "antd/es/table";
import { ICommunication } from "../../@types/communication";
import ReactAudioPlayer from "react-audio-player";
interface IProps {
  incidentId: string;
  onClose: Function;
}

const convertDateTime = (dbDateString: string) => {
  if (!dbDateString) return "";
  const dbDate = new Date(dbDateString);
  const dateTimeFormatted = new Intl.DateTimeFormat("it-IT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris",
  }).format(dbDate);
  return dateTimeFormatted;
};

const IncidentDetail: FC<IProps> = ({ incidentId, onClose }) => {
  const dispatch = useAppDispatch();
  const { incidentDetail } = useAppSelector((state) => state.incident);

  useEffect(() => {
    if (incidentId) {
      dispatch(fetchIncidentDetail(incidentId));
    }
  }, [dispatch, incidentId]);

  const columns: ColumnsType<ICommunication> = [
    {
      title: "Type",
      key: "type",
      render: (_, record) => <div>{record.type}</div>,
    },
    {
      title: "Receiver",
      key: "receiver",
      render: (_, record) => <div>{record.receiver}</div>,
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => <div>{record.status}</div>,
    },
    {
      title: "Subject",
      key: "subject",
      render: (_, record) => <div>{record.mail_subject}</div>,
    },
    {
      title: "Content",
      key: "content",
      render: (_, record) =>
        record.type === "phone" ? (
          <ReactAudioPlayer
            src={`${process.env.REACT_APP_BACKEND_URL}/phone-script/${record.incident_id}`}
            controls
          />
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html: record.mail_content || record.sms_content,
            }}
          />
        ),
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (_, record) => <div>{convertDateTime(record.created_at)}</div>,
    },
  ];

  return (
    <Modal
      className="rounded-3xl"
      title={
        <div className="text-center text-lg text-white">Incident Detail</div>
      }
      width={"60vw"}
      open={Boolean(incidentId)}
      onCancel={() => onClose()}
      footer={null}>
      <Row gutter={[16, 16]}>
        <Col span={8} className="text-base flex items-center">
          <div className="text-base text-container">Level:</div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          <div className="flex justify-end">
            {get(incidentDetail, "level") === "warning" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.25em"
                height="1.25em"
                viewBox="0 0 512 512">
                <path
                  fill="none"
                  stroke="#ffa64d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M85.57 446.25h340.86a32 32 0 0 0 28.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0 0 28.17 47.17Z"></path>
                <path
                  fill="none"
                  stroke="#ffa64d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="m250.26 195.39l5.74 122l5.73-121.95a5.74 5.74 0 0 0-5.79-6h0a5.74 5.74 0 0 0-5.68 5.95Z"></path>
                <path
                  fill="#ffa64d"
                  d="M256 397.25a20 20 0 1 1 20-20a20 20 0 0 1-20 20Z"></path>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.25em"
                height="1.25em"
                viewBox="0 0 256 256"
                className="fill-red-500">
                <path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84a84.09 84.09 0 0 1-84 84Zm-12-80V80a12 12 0 0 1 24 0v52a12 12 0 0 1-24 0Zm28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16Z"></path>
              </svg>
            )}
            <div
              className={`capitalize ml-2 ${
                get(incidentDetail, "level") === "critical" ? "text-error" : ""
              }`}>
              {get(incidentDetail, "level")}
            </div>
          </div>
        </Col>
        <Col span={8} className="text-base">
          <div className="text-base text-container">Device:</div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          {get(incidentDetail, "device_detail.name")}
        </Col>
        <Col span={8} className="text-base">
          <div className="text-base text-container">Channel:</div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          {get(incidentDetail, "channel_detail.name")}
        </Col>
        <Col span={8} className="text-base">
          <div className="text-base text-container">
            Threshold Condition Details:
          </div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          <ul>
            <Row>
              <Col className="text-base text-secondary text-right" span={18}>
                Value:
              </Col>
              <Col className="text-base text-secondary text-right" span={6}>
                {get(incidentDetail, "channel_threshold.value")}{" "}
                {get(incidentDetail, "channel_detail.unit")}
              </Col>
              <Col className="text-base text-secondary text-right" span={18}>
                Duration:
              </Col>
              <Col className="text-base text-secondary text-right" span={6}>
                {get(incidentDetail, "channel_threshold.duration", 0) || 0}
              </Col>
              {get(incidentDetail, "channel_threshold.condition") && (
                <>
                  <Col
                    className="text-base text-secondary text-right"
                    span={18}>
                    Condition:
                  </Col>
                  <Col className="text-base text-secondary text-right" span={6}>
                    {get(incidentDetail, "channel_threshold.condition")}
                  </Col>
                </>
              )}
              {get(incidentDetail, "channel_threshold.comparator") && (
                <>
                  <Col
                    className="text-base text-secondary text-right"
                    span={18}>
                    Comparator:
                  </Col>
                  <Col className="text-base text-secondary text-right" span={6}>
                    {get(incidentDetail, "channel_threshold.comparator")}
                  </Col>
                </>
              )}
              <Col className="text-base text-secondary text-right" span={18}>
                Created at:
              </Col>
              <Col className="text-base text-secondary text-right" span={6}>
                {convertDateTime(
                  get(incidentDetail, "channel_threshold.created_at"),
                )}
              </Col>
            </Row>
          </ul>
        </Col>
        <Col span={8} className="text-base">
          <div className="text-base text-container">Created At:</div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          {convertDateTime(get(incidentDetail, "original_created_at"))}
        </Col>
        <Col span={8} className="text-base">
          <div className="text-base text-container">Closed At:</div>
        </Col>
        <Col span={16} className="text-base text-secondary text-right">
          {convertDateTime(get(incidentDetail, "original_updated_at"))}
        </Col>
      </Row>
      <Divider />
      <h4 className="mb-4 text-xl text-container text-center">Email & Sms</h4>
      <Table
        columns={columns}
        dataSource={get(incidentDetail, "communications")}
      />
    </Modal>
  );
};

export default IncidentDetail;
