import { Checkbox, Col, Row, Spin } from "antd";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fetchAllDevices } from "../../../../api/reports";

interface Props {
  checked: string[];
  onChange: Function;
}

const DeviceList: React.FC<Props> = ({ checked, onChange }) => {
  const dispatch = useAppDispatch();
  const { devices, loadingDevice } = useAppSelector((state) => state.report);
  useEffect(() => {
    dispatch(fetchAllDevices());
  }, []);

  const _onChange = (e: any) => {
    console.log(111, e)
    onChange(e);
  };
  return (
    <div className="border-container border p-6 rounded-2xl relative h-full">
      <h3 className="text-2xl text-container mb-4">Device List</h3>
      {loadingDevice ? (
        <Spin />
      ) : (
        <div
          className="scroller"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            overflowX: "hidden",
          }}>
          <Checkbox.Group value={checked} onChange={_onChange}>
            <Row gutter={[16, 16]}>
              {devices.map((item) => (
                <Col span={24}>
                  <Checkbox value={item.id}>{item.name}</Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
      )}
    </div>
  );
};

export default DeviceList;
