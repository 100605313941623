import ReportHistoryTable from "../components/report-history/ReportHistoryTable";

const ReportHistory = () => {
  return (
    <section>
      <div className="text-4xl text-container">Report History</div>
      <div className="mt-4">
        <ReportHistoryTable />
      </div>
    </section>
  );
};

export default ReportHistory;
