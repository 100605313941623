import LabOperatorInstance from "../components/setting/LabOperatorInstance";
import { Col, Row } from "antd";
import Notification from "../components/setting/Notification/index";
import { useAppDispatch } from "../app/hooks";
import { useEffect } from "react";
import { fetchSetting } from "../api/setting";

const Setting = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchSetting());
  });

  return (
    <section>
      <div className="text-4xl text-container">Setting</div>
      <Row className="mt-8">
        <Col span={24} className="mb-4">
          <LabOperatorInstance />
        </Col>

        <Col span={24}>
          <Notification />
        </Col>
      </Row>
    </section>
  );
};

export default Setting;
