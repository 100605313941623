import { Button, Col, Row, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import DeviceList from "./components/DeviceList";
import Time from "./components/Time";
import Interval from "./components/IntervalResolution";
import Email from "./components/Email";
import dayjs, { Dayjs } from "dayjs";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  addEmail,
  fetchListEmailReportSetting,
  generateManualReport,
  removeEmail,
} from "../../../api/reports";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { debounce } from "lodash";
import IntervalRemove from "./components/IntervalRemove";
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const ManualReport = () => {
  const dispatch = useAppDispatch();
  const { listManualEmail } = useAppSelector((state) => state.report);
  const [deviceList, setDeviceList] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<RangeValue>([
    dayjs().subtract(7, "day"),
    dayjs(),
  ]);
  const [interval, setInterval] = useState<number | null>(1);
  const [intervalRemove, setIntervalRemove] = useState<number | null>(30);

  const handleReport = useCallback(
    debounce(() => {
      if (!deviceList.length) {
        message.error("Please choose at least one device");
        return;
      }
      if (!listManualEmail.length) {
        message.error("Please add at least one receiver");
        return;
      }
      const body = {
        devices: deviceList,
        from: dateRange?.[0],
        to: dateRange?.[1],
        interval,
        receivers: listManualEmail,
        offsetRemoveDay: intervalRemove,
      };
      dispatch(generateManualReport(body));
      message.success("Sent generate reporting request success");
    }, 200),
    [deviceList, listManualEmail, dateRange, interval, intervalRemove],
  );

  const onChangeReceiver = (email: string, isRemove: boolean) => {
    if (!email) {
      message.error("Please input email");
      return;
    }
    if (!isRemove && listManualEmail.includes(email.trim())) {
      message.error("Duplicate Email");
      return;
    }
    if (isRemove) {
      dispatch(removeEmail(email));
    } else {
      dispatch(addEmail(email));
    }
  };

  useEffect(() => {
    dispatch(fetchListEmailReportSetting());
  }, []);

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <DeviceList
            checked={deviceList}
            onChange={(value: string[]) => setDeviceList(value)}
          />
        </Col>
        <Col span={8}>
          <Time value={dateRange} onChange={setDateRange} />
        </Col>
        <Col span={8}>
          <Interval value={interval} onChange={setInterval} />
        </Col>
        <Col span={8}>
          <Email value={listManualEmail} onChange={onChangeReceiver} />
        </Col>
        <Col span={8}>
          <IntervalRemove value={intervalRemove} onChange={setIntervalRemove} />
        </Col>
        <Col span={24} className="flex justify-center">
          <Button
            onClick={handleReport}
            className="text-white text-base rounded-3xl bg-primary py-5 px-6 flex items-center justify-center">
            Generate Report
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ManualReport;
