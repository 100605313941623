import { createSlice } from "@reduxjs/toolkit";
import { INotificationContent } from "../../@types/notification-content";
import { fetchEmailByNotificationContent, fetchNotificationContent, fetchPhoneByNotificationContent, fetchPhoneCallByNotificationContent } from "../../api/notification-content";
import { addEmail, addPhone, addPhoneCall, removeEmail, removePhone, removePhoneCall, updateAdvanceContent } from "../../api/setting";
import { message } from "antd";

const initialState: INotificationContent = {
  id: "",
  type: "",
  mailSubject: "",
  mailContent: "",
  voiceContent: "",
  smsContent: "",
  emails: [],
  phones: [],
  phoneCalls: [],
  phoneCallLoading: false,
  phoneLoading: false,
  emailLoading: false,
  contentLoading: false,
  loadingAdvanceContent: false,
  advanceContent: "",
};

const notificationContentSlice = createSlice({
  name: "notificationContent",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationContent.fulfilled, (state, action) => {
      state.id = action.payload.id
      state.type = action.payload.type
      state.mailSubject = action.payload.mail_subject
      state.mailContent = action.payload.mail_content
      state.voiceContent = action.payload.voice_content
      state.smsContent = action.payload.sms_content
      state.advanceContent = action.payload.advance_content
      state.contentLoading = false
    })

    builder.addCase(fetchNotificationContent.pending, (state, action) => {
      state.contentLoading = true
    })

    builder.addCase(addEmail.fulfilled, (state, action) => {
      state.emails = [...state.emails, action.payload.email]
    })

    builder.addCase(addPhone.fulfilled, (state, action) => {
      state.phones = [...state.phones, action.payload.phone]
    })

    builder.addCase(addPhoneCall.fulfilled, (state, action) => {
      state.phoneCalls = [...state.phoneCalls, action.payload.phone]
    })

    builder.addCase(removeEmail.fulfilled, (state, action) => {
      state.emails = state.emails.filter(email => email !== action.payload.email)
    })

    builder.addCase(removePhone.fulfilled, (state, action) => {
      state.phones = state.phones.filter(phone => phone !== action.payload.phone)
    })

    builder.addCase(removePhoneCall.fulfilled, (state, action) => {
      state.phoneCalls = state.phoneCalls.filter(phone => phone !== action.payload.phone)
    })

    builder.addCase(fetchEmailByNotificationContent.fulfilled, (state, action) => {
      state.emails = action.payload
      state.emailLoading = false
    })

    builder.addCase(fetchEmailByNotificationContent.pending, (state, action) => {
      state.emailLoading = true
    })

    builder.addCase(fetchPhoneByNotificationContent.fulfilled, (state, action) => {
      state.phones = action.payload
      state.phoneLoading = false
    })

    builder.addCase(fetchPhoneByNotificationContent.pending, (state, action) => {
      state.phoneLoading = true
    })

    builder.addCase(fetchPhoneCallByNotificationContent.fulfilled, (state, action) => {
      state.phoneCalls = action.payload
      state.phoneCallLoading = false
    })

    builder.addCase(fetchPhoneCallByNotificationContent.pending, (state, action) => {
      state.phoneCallLoading = true
    })

    builder.addCase(updateAdvanceContent.pending, (state, action) => {
      state.loadingAdvanceContent = true
    })
    builder.addCase(updateAdvanceContent.fulfilled, (state, action) => {
      state.loadingAdvanceContent = false
      message.success("Update advance content table successfully");
    })

  },
});


export default notificationContentSlice.reducer;
