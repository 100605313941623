import { Button, Col, Row, Switch, message } from "antd";
import { useCallback, useEffect } from "react";
import DeviceList from "./components/DeviceList";
import IntervalResolution from "./components/IntervalResolution";
import ReportInterval from "./components/IntervalReport";
import Email from "./components/Email";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchReportSchedule,
  updateReportScheduleAPI,
} from "../../../api/reports";
import { updateReportSchedule } from "../../../app/slices/report.slice";
import { debounce, map, filter } from "lodash";
import IntervalRemove from "./components/IntervalRemove";

const ScheduledReport = () => {
  const dispatch = useAppDispatch();
  const { reportSchedule } = useAppSelector((state) => state.report);

  useEffect(() => {
    dispatch(fetchReportSchedule());
  }, []);

  const handleToggleActive = (value: boolean) => {
    dispatch(
      updateReportSchedule({
        type: "active",
        value,
      }),
    );
  };

  const handleSave = useCallback(
    debounce(() => {
      if (!reportSchedule.devices.length) {
        message.error("Please choose at least one device");
        return;
      }
      if (!reportSchedule.receivers.length) {
        message.error("Please add at least one receiver");
        return;
      }
      if (!reportSchedule.interval.length) {
        message.error("Please pick report interval");
        return;
      }
      dispatch(updateReportScheduleAPI(reportSchedule));
      message.success("Saving schedule report");
    }, 200),
    [reportSchedule],
  );

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24} className="flex items-center">
          <h3 className="text-2xl text-container mr-4">
            Scheduled Reports Active
          </h3>
          <Switch
            checked={reportSchedule.active}
            onChange={handleToggleActive}
          />
        </Col>
        <Col span={8}>
          <DeviceList
            checked={reportSchedule.devices}
            onChange={(value: string[]) =>
              dispatch(
                updateReportSchedule({
                  type: "devices",
                  value,
                }),
              )
            }
          />
        </Col>
        <Col span={8}>
          <ReportInterval
            value={reportSchedule.interval}
            onChange={(value) =>
              dispatch(
                updateReportSchedule({
                  type: "interval",
                  value,
                }),
              )
            }
          />
        </Col>
        <Col span={8}>
          <IntervalResolution
            value={reportSchedule.resolution}
            onChange={(value) =>
              dispatch(
                updateReportSchedule({
                  type: "resolution",
                  value,
                }),
              )
            }
          />
        </Col>
        <Col span={8}>
          <Email
            value={reportSchedule.receivers}
            onChange={(value, isRemove) => {
              let newValue = reportSchedule.receivers;
              if (isRemove) {
                newValue = filter(newValue, (v) => v !== value);
              } else {
                newValue = [...newValue, value];
              }
              dispatch(
                updateReportSchedule({
                  type: "receivers",
                  value: newValue,
                }),
              );
            }}
          />
        </Col>
        <Col span={8}>
          <IntervalRemove
            value={reportSchedule.offsetRemoveDay}
            onChange={(value) => {
              dispatch(
                updateReportSchedule({
                  type: "offsetRemoveDay",
                  value,
                }),
              );
            }}
          />
        </Col>
        <Col span={24} className="flex justify-center">
          <Button
            onClick={handleSave}
            className="text-white text-base rounded-3xl bg-primary py-5 px-6 flex items-center justify-center">
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ScheduledReport;
