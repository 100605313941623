import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { fetchCommunications } from "../../api/communication";
import { ICommunication } from "../../@types/communication";

const initialState: { list: ICommunication[], total: number, currentPage: number, pageSize: number, loading: boolean } = {
  list: [],
  total: 0,
  currentPage: 1,
  pageSize: 10,
  loading: false
};

const incidentSlice = createSlice({
  name: "communication",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommunications.fulfilled, (state, action) => {
      state.list = action.payload.list;
      state.total = action.payload.totalCount
      state.loading = false
    });
    builder.addCase(fetchCommunications.pending, (state, action) => {
      state.loading = true
    })
  },
});

export const { setPage } = incidentSlice.actions;

export default incidentSlice.reducer;
