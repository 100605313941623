import { Col, InputNumber, Row, Slider } from "antd";
interface Props {
  value: number | null;
  onChange: (value: number | null) => void;
}

const IntervalResolution: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className="border-container border p-6 rounded-2xl relative h-full">
      <h3 className="text-2xl text-container mb-4">Interval / Resolution</h3>
      <Row className="w-full flex items-center" gutter={[16, 16]}>
        <Col span={24}>
          <Slider
            min={1}
            max={1440}
            onChange={onChange}
            value={typeof value === "number" ? value : 0}
          />
        </Col>
        <Col span={6}>
          <InputNumber
            min={1}
            max={1440}
            value={value}
            style={{ width: "100%" }}
            onChange={onChange}
          />
        </Col>
        <Col span={4}>
          <h3>minutes</h3>
        </Col>
      </Row>
    </div>
  );
};

export default IntervalResolution;
