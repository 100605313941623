import { HistoryTable } from "../components/incident/HistoryTable";

const History = () => {
  return (
    <section>
      <div className="text-4xl text-container">Incidents</div>
      <div className="mt-4">
        <HistoryTable />
      </div>
    </section>
  );
};

export default History;
