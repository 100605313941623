import axios from 'axios'
import { BACKEND_URL } from '../constants'

const instance = axios.create({
    baseURL : BACKEND_URL
})

instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('subscriptionId');
      if (token) {
        config.headers['subscription-id'] = `${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

export default instance