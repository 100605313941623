import { Row, Col, Button } from "antd";
import { useEffect, useState } from "react";
import Email from "./Email";
import Phone from "./Phone";
import Message from "./Message";
import Box from "../../common/Box";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { fetchNotificationType } from "../../../api/notification-type";
import { fetchNotificationContent } from "../../../api/notification-content";

import PhoneCall from "./PhoneCall";
const Notification = () => {
  const [saving, setSaving] = useState<boolean>(false);
  const { loadingMessage: loading, platform } = useAppSelector(
    (state) => state.setting,
  );
  const { list } = useAppSelector((state) => state.notificationType);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (platform) {
      dispatch(fetchNotificationType(platform));
    }
  }, [dispatch, platform]);

  const [type, setType] = useState<string>();

  useEffect(() => {
    if (list.length) {
      setType(list[0].name);
    }
  }, [list]);

  useEffect(() => {
    if (type) {
      dispatch(fetchNotificationContent(type));
    }
  }, [dispatch, type]);

  if (!list.length) {
    return null;
  }
  return (
    <>
      <section className="bg-primaryBg p-8 rounded-2xl">
        <div className="mb-8 flex items-center justify-between">
          <h3 className="text-2xl text-container ">Notification</h3>
          <Button
            className="text-white text-base rounded-3xl bg-primary py-5 px-6 flex items-center justify-center"
            onClick={() => setSaving(true)}
            loading={loading}>
            Save
          </Button>
        </div>

        <Row gutter={[32, 32]}>
          <Col xs={24} >
            <Box title="Message Content (Email / SMS / Audio Content)">
              <Message triggerSave={saving} setSaving={setSaving} />
            </Box>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <Box title="Emails">
              <Email />
            </Box>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <Box title="SMS Phone Numbers">
              <Phone />
            </Box>
          </Col>
          <Col xs={24} lg={12} xl={8}>
            <Box title="Phone Call Numbers">
              <PhoneCall />
            </Box>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Notification;
