import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from './agent'
import { BACKEND_URL } from "../constants";
import { QueryInput } from "../@types/incident";

export const fetchCommunications = createAsyncThunk(
  "incident/fetchCommunications",
  async (query: QueryInput) => {
    const response = await agent.get(`${BACKEND_URL}/communications?page=${query.page}&pageSize=${query.pageSize}`);
    return response.data
  },
);
