import { Table } from "antd";
import { useEffect } from "react";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setPage } from "../../app/slices/report.slice";
import { fetchReports } from "../../api/reports";
import { IReport } from "../../@types/report";
import PreviewPdf from "./PreviewPdf";
import { BACKEND_URL } from "../../constants";
import { Badge, Space } from "antd";
import { map, split } from "lodash";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';

const convertDateTime = (dbDateString: string) => {
  const dbDate = new Date(dbDateString);
  const dateTimeFormatted = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris", // CEST time zone
  }).format(dbDate);
  return dateTimeFormatted;
};
const columns: ColumnsType<IReport> = [
  {
    title: "Device",
    key: "device",
    render: (_, record) => <div>{record.device_name}</div>,
  },
  {
    title: "Device Id",
    key: "deviceId",
    render: (_, record) => <div>{record.device_id}</div>,
  },
  {
    title: "Type",
    key: "type",
    render: (_, record) => (
      <div className="uppercase">
        {record.report_schedule_interval_type || record.type}
      </div>
    ),
  },
  {
    title: "Receivers",
    key: "receiver",
    render: (_, record) => (
      <Stack direction="row" spacing={1}>
        {map(split(record.receivers, ","), (receiver) => (
        <Chip icon={<FaceIcon />} label={receiver} />
        ))}
      </Stack>
    ),
  },
  {
    title: "Start Date",
    key: "startDate",
    render: (_, record) => <div>{convertDateTime(record.from)}</div>,
  },
  {
    title: "End Date",
    key: "endDate",
    render: (_, record) => <div>{convertDateTime(record.to)}</div>,
  },
  {
    title: "Created At",
    key: "createdAt",
    render: (_, record) => <div>{convertDateTime(record.created_at)}</div>,
  },
  {
    title: "Pdf",
    key: "pdf",
    render: (_, record) => {
      if (
        record.file_path &&
        record.file_host === BACKEND_URL &&
        record.status !== "delete"
      ) {
        return <PreviewPdf url={`${BACKEND_URL}/pdf/${record.id}`} />;
      }
      return null;
    },
  },
];

const ReportHistoryTable = () => {
  const { list, total, currentPage, pageSize, loadingList } = useAppSelector(
    (s) => s.report,
  );

  const dispatch = useAppDispatch();

  const handleChangePage = (p: number) => {
    dispatch(setPage(p));
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    dispatch(
      fetchReports({
        page: currentPage,
        pageSize,
      }),
    );
  }, [currentPage, dispatch, pageSize]);

  return (
    <Table
      scroll={{ x: 1400 }}
      columns={columns}
      dataSource={list}
      loading={loadingList}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total,
        onChange: handleChangePage,
        showSizeChanger: false,
      }}
    />
  );
};

export default ReportHistoryTable;
