import { CommunicationsTable } from "../components/email-sms/CommunicationsTable";

const History = () => {
  return (
    <section>
      <div className="text-4xl text-container">Email & SMS</div>
      <div className="mt-4">
        <CommunicationsTable />
      </div>
    </section>
  );
};

export default History;
