import { Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IIncident } from "../../@types/incident";
import { get } from "lodash";
import { clearIncidentDetail, setPage } from "../../app/slices/incident.slice";
import { fetchIncidents } from "../../api/incidents";
import IncidentDetail from "./Detail";
import { EyeOutlined } from "@ant-design/icons";

const convertDateTime = (dbDateString: string) => {
  const dbDate = new Date(dbDateString);
  const dateTimeFormatted = new Intl.DateTimeFormat("it-IT", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris",
  }).format(dbDate);
  return dateTimeFormatted;
};

export const HistoryTable = () => {
  const [selectedIncident, setSelectedIncident] = useState<string>("");
  const columns: ColumnsType<IIncident> = [
    {
      title: "Level",
      key: "level",
      width: "5%",
      fixed: true,
      render: (_, record) => (
        <div>
          {record.level === "warning" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 512 512">
              <path
                fill="none"
                stroke="#ffa64d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M85.57 446.25h340.86a32 32 0 0 0 28.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0 0 28.17 47.17Z"></path>
              <path
                fill="none"
                stroke="#ffa64d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="32"
                d="m250.26 195.39l5.74 122l5.73-121.95a5.74 5.74 0 0 0-5.79-6h0a5.74 5.74 0 0 0-5.68 5.95Z"></path>
              <path
                fill="#ffa64d"
                d="M256 397.25a20 20 0 1 1 20-20a20 20 0 0 1-20 20Z"></path>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 256 256"
              className="fill-red-500">
              <path d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84a84.09 84.09 0 0 1-84 84Zm-12-80V80a12 12 0 0 1 24 0v52a12 12 0 0 1-24 0Zm28 40a16 16 0 1 1-16-16a16 16 0 0 1 16 16Z"></path>
            </svg>
          )}
        </div>
      ),
    },
    {
      title: "Device",
      key: "device",
      render: (_, record) => <div>{get(record.device_detail, "name")}</div>,
    },
    {
      title: "Channel",
      key: "channel",
      render: (_, record) => <div>{get(record.channel_detail, "name")}</div>,
    },
    {
      title: "Threshold Condition Details",
      key: "threshold",
      width: "30%",
      render: (_, record) => (
        <ul>
          <Row>
            <Col span={5}>Value:</Col>
            <Col span={19}>
              {record.channel_threshold.value} {record.channel_detail.unit}
            </Col>
            <Col span={5}>Duration:</Col>
            <Col span={19}>{record.channel_threshold.duration || 0}</Col>
            {record.channel_threshold.condition && (
              <>
                <Col span={5}>Condition:</Col>
                <Col span={19}>{record.channel_threshold.condition}</Col>
              </>
            )}
            {record.channel_threshold.comparator && (
              <>
                <Col span={5}>Comparator:</Col>
                <Col span={19}>{record.channel_threshold.comparator}</Col>
              </>
            )}
            <Col span={5}>Created at:</Col>
            <Col span={19}>
              {convertDateTime(record.channel_threshold.created_at)}
            </Col>
          </Row>
        </ul>
      ),
    },
    {
      title: "Created At",
      key: "createAt",
      render: (_, record) => (
        <div>{convertDateTime(record.original_created_at)}</div>
      ),
    },
    {
      title: "Closed At",
      key: "closeAt",
      render: (_, record) => <div>{convertDateTime(record.closed_at)}</div>,
    },
    {
      title: "Action",
      key: "actions",
      width: "6%",
      render: (_, record) => (
        <div
          className="cursor-pointer"
          onClick={() => setSelectedIncident(record.id)}>
          <EyeOutlined />
        </div>
      ),
    },
  ];
  const { list, total, currentPage, pageSize, loading } = useAppSelector(
    (s) => s.incident,
  );
  const dispatch = useAppDispatch();
  const handleChangePage = (p: number) => {
    dispatch(setPage(p));
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, []);

  useEffect(() => {
    dispatch(
      fetchIncidents({
        page: currentPage,
        pageSize,
      }),
    );
  }, [currentPage, dispatch, pageSize]);

  return (
    <>
      <Table
        scroll={{
          y: "64vh",
          x: 1430,
        }}
        columns={columns}
        loading={loading}
        dataSource={list}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total,
          onChange: handleChangePage,
          showSizeChanger: false,
        }}
      />
      <IncidentDetail
        incidentId={selectedIncident}
        onClose={() => {
          setSelectedIncident("");
          dispatch(clearIncidentDetail());
        }}
      />
    </>
  );
};
