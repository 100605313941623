import { EyeFilled } from "@ant-design/icons";
import { FC, useState, useRef } from "react";
import { useClickAway } from "react-use";

const PreviewPdf: FC<{ url: string }> = ({ url }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickAway(ref, () => {
    setOpen(false);
  });
  return (
    <div>
      <EyeFilled onClick={() => setOpen((v) => !v)} />
      {open && (
        <div
          ref={ref}
          className="fixed z-50"
          style={{ width: "80vw", height: "80vh", top: "10vh", left: "10vw" }}>
          <object data={url} type="application/pdf" width="100%" height="100%">
            <div className="bg-white text-center">Can not load PDF file</div>
          </object>
        </div>
      )}
    </div>
  );
};

export default PreviewPdf;
