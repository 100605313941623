import { Checkbox, Col, Row } from "antd";

interface Props {
  value: string[];
  onChange: (v: string[]) => void;
}

const ReportInterval: React.FC<Props> = ({ value, onChange }) => {
  const options = [
    { label: "Daily", value: "daily" },
    { label: "Monthly", value: "monthly" },
    { label: "Weekly", value: "weekly" },
  ];

  return (
    <div className="border-container border p-6 rounded-2xl relative h-full">
      <h3 className="text-2xl text-container mb-4">Report Interval</h3>
      <Checkbox.Group value={value} onChange={(e) => onChange(e as string[])}>
        <Row gutter={[16, 16]}>
          {options.map((item) => (
            <Col span={12}>
              <Checkbox value={item.value}>{item.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default ReportInterval;
