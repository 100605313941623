import { createAsyncThunk } from "@reduxjs/toolkit";
import agent from './agent'
import { BACKEND_URL } from "../constants";
import { map } from 'lodash'


export const fetchNotificationContent = createAsyncThunk(
    "notificationContent/fetchNotificationContent",
    async (type: string) => {
        const response = await agent.get(`${BACKEND_URL}/settings/notification-content/${type}`);
        return response.data
    },
);

export const fetchEmailByNotificationContent = createAsyncThunk(
    "notificationContent/fetchEmailByNotificationContent",
    async (type: string) => {
        const response = await agent.get(`${BACKEND_URL}/settings/notification-content/${type}/email`);
        return map(response.data, 'email')
    },
);

export const fetchPhoneByNotificationContent = createAsyncThunk(
    "notificationContent/fetchPhoneByNotificationContent",
    async (type: string) => {
        const response = await agent.get(`${BACKEND_URL}/settings/notification-content/${type}/phone`);
        return map(response.data, 'phone')
    },
);

export const fetchPhoneCallByNotificationContent = createAsyncThunk(
    "notificationContent/fetchPhoneCallByNotificationContent",
    async (type: string) => {
        const response = await agent.get(`${BACKEND_URL}/settings/notification-content/${type}/phone-call`);
        return map(response.data, 'phone')
    },
);

export const updateNotificationContentMethod = createAsyncThunk(
    "notificationContent/updateNotificationContentMethod",
    async (data: any) => {
        const response = await agent.patch(`${BACKEND_URL}/settings/notification-content/methods`, data);
        return response.data
    },
);
