import { createAsyncThunk } from "@reduxjs/toolkit"
import { BACKEND_URL } from "../constants"
import agent from './agent'
import { IAddEmail, IAddPhone, IEmailContent, ILapOperator, ISetting } from "../@types/setting"
import { map } from "lodash"


export const fetchSetting = createAsyncThunk('setting/fetchSetting', async () => {
    const { data } = await agent.get(`${BACKEND_URL}/settings`)
    return {
        ...data,
        phones: map(data.phones, 'phone'),
        emails: map(data.emails, 'email'),
        emailContent: data.email_content,
        emailSubject: data.email_subject,
        lapOperator: {
            clientId: data.lab_operator_client_id,
            clientSecret: data.lab_operator_client_secret,
            tenant: data.lab_operator_client_tenant
        },
        voiceContent: data.voice_content,
        platform: data.platform,
        advanceContent : data.advance_content
    } as ISetting
})

export const updateLabOperatorInstance = createAsyncThunk('setting/updateLabOperatorInstance', async (body: ILapOperator) => {
    await agent.patch(`${BACKEND_URL}/settings/lab-operator-instance`, { ...body })
    return body
})

export const addEmail = createAsyncThunk('setting/addEmail', async (input: IAddEmail) => {
    await agent.post(`${BACKEND_URL}/settings/emails`, { email: input.email, incidentLevel: input.incidentLevel })
    return input
})

export const addPhone = createAsyncThunk('setting/addPhone', async (input: IAddPhone) => {
    await agent.post(`${BACKEND_URL}/settings/phones`, { phone: input.phone, incidentLevel: input.incidentLevel })
    return input
})

export const addPhoneCall = createAsyncThunk('setting/addPhoneCall', async (input: IAddPhone) => {
    await agent.post(`${BACKEND_URL}/settings/phone-calls`, { phone: input.phone, incidentLevel: input.incidentLevel })
    return input
})

export const removeEmail = createAsyncThunk('setting/removeEmail', async (input: Record<string, any>) => {
    await agent.delete(`${BACKEND_URL}/settings/emails/${input.email}/${input.type}`)
    return input
})

export const removePhone = createAsyncThunk('setting/removePhone', async (input: Record<string, any>) => {
    await agent.delete(`${BACKEND_URL}/settings/phones/${input.phone}/${input.type}`)
    return input
})

export const removePhoneCall = createAsyncThunk('setting/removePhoneCall', async (input: Record<string, any>) => {
    await agent.delete(`${BACKEND_URL}/settings/phone-calls/${input.phone}/${input.type}`)
    return input
})

export const updateEmailContent = createAsyncThunk('setting/email-contents', async (input: IEmailContent) => {
    await agent.patch(`${BACKEND_URL}/settings/email-content`, input)
    return input
})

export const updateVoiceContent = createAsyncThunk('setting/voice-content', async (input: Record<string, any>) => {
    await agent.patch(`${BACKEND_URL}/settings/voice-content`, { content: input.content })
    return input
})

export const updateAdvanceContent = createAsyncThunk('setting/advance-content', async (input: Record<string, any>) => {
    await agent.patch(`${BACKEND_URL}/settings/advance-content`, { content: input.content })
    return input
})

export const getSubscription = async (slug: string) => {
    const response = await agent.get(`${BACKEND_URL}/subscriptions/${slug}`)
    return response
}
