import React from "react";
import { Tabs } from "antd";
import ManualReport from "./ManualReport";
import ScheduledReport from "./ScheduledReport";

const Report: React.FC = () => (
  <Tabs
    defaultActiveKey="1"
    items={[
      {
        label: "MANUAL REPORTS",
        key: "1",
        children: <ManualReport />,
      },
      {
        label: "SCHEDULED REPORTS",
        key: "3",
        children: <ScheduledReport />,
      },
    ]}
  />
);

export default Report;
