import { DatePicker } from "antd";
import { Dayjs } from "dayjs";

type RangeValue = [Dayjs | null, Dayjs | null] | null;
const dateFormat = "YYYY/MM/DD";
const { RangePicker } = DatePicker;

interface Props {
  value: RangeValue;
  onChange: (value: RangeValue) => void;
}

const Time: React.FC<Props> = ({ value, onChange }) => {
  return (
    <div className="border-container border p-6 rounded-2xl relative h-full">
      <h3 className="text-2xl text-container mb-4">
        Start time & Stop time & Date
      </h3>
      <RangePicker
        className="w-full"
        format={dateFormat}
        value={value}
        onChange={(value) => onChange(value as any)}
      />
    </div>
  );
};

export default Time;
