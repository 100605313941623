import { Input, Space, message, Spin } from "antd";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { addPhone, removePhone } from "../../../api/setting";
import removeIcon from "../../../assets/images/remove.png";
import { fetchPhoneByNotificationContent } from "../../../api/notification-content";
import checkIcon from "../../../assets/images/check.png";

const Phone = () => {
  const { phones, phoneLoading } = useAppSelector(
    (state) => state.notificationContent,
  );
  const dispatch = useAppDispatch();
  const [incidentLevel, setIncidentLevel] = useState<string>("");
  const { list } = useAppSelector((state) => state.notificationType);
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (list.length) {
      setIncidentLevel(list[0].name);
    }
  }, [list]);

  useEffect(() => {
    if (incidentLevel) {
      dispatch(fetchPhoneByNotificationContent(incidentLevel));
    }
  }, [dispatch, incidentLevel]);

  const handleAdd = useCallback(
    debounce(async (phone) => {
      if (!phone || !incidentLevel) return;
      if (phones.includes(phone.trim())) {
        message.error("Phone exists");
        return;
      }
      await dispatch(
        addPhone({
          phone,
          incidentLevel,
        }),
      );
      message.success("Add phone successfully");
      setValue("");
    }, 200),
    [phones, incidentLevel],
  );

  const handleRemove = useCallback(
    debounce(async (phone) => {
      await dispatch(
        removePhone({
          phone,
          type: incidentLevel,
        }),
      );
      message.success("Remove phone successfully");
    }, 200),
    [incidentLevel],
  );
  return (
    <div className="w-full">
      <div className="w-full flex mb-4">
        {list.map((option, index) => (
          <div
            className={`w-1/2 justify-center flex items-center cursor-pointer text-container text-base py-3 px-5 ${
              incidentLevel === option.name
                ? "bg-primary text-white"
                : "bg-white text-container"
            } ${
              index === 0 ? "rounded-l-3xl" : "rounded-r-3xl"
            } border-container border capitalize`}
            onClick={() => setIncidentLevel(option.name)}>
            {incidentLevel === option.name && (
              <img className="mr-2" src={checkIcon} alt="" />
            )}{" "}
            {option.name}
          </div>
        ))}
      </div>
      <Space.Compact className="w-full">
        <Input
          disabled={phoneLoading}
          className="ant-input-large"
          placeholder="Input phone"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleAdd(value)}
        />
      </Space.Compact>
      {phoneLoading ? (
        <div className="m-4 flex items-center justify-center">
          <Spin />
        </div>
      ) : (
        <div
          style={{ maxHeight: "200px", overflowY: "auto" }}
          className="grid grid-cols-1 gap-4 mt-4 px-4 scroller">
          {phones.map((phone) => (
            <div key={phone} className="flex items-center justify-between">
              <h5 className="text-left text-secondary text-base">{phone}</h5>
              <img
                className="cursor-pointer hover:scale-110 transition duration-100"
                src={removeIcon}
                alt="Icon"
                onClick={() => handleRemove(phone)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Phone;
